import React, { Component } from "react";
import { Link } from "gatsby"
import Tags from "./tag"

export default props => (
  
    <div className="col-4" style={{textAlign: `center` }} textalign="center">
        <Link to={`blog/`+props.node.fields.slug} style={{height: `100%`, width: `100%`}}>
        <div className="card_home" style={{maxHeight: `300px`, backgroundImage: `url(${ props.node.frontmatter.thumbnail})`}}>
            <div style={{marginTop: "25px", display: 'block', position: 'absolute', textAlign: `center`, padding: `10px`, fontSize: `18px`, fontWeight: `600`, lineHeight: `20px`, backgroundColor: `#79625d`, color: '#26BBBA', width: `100%`, borderBottom: `1px solid #c3c3c3`, borderTop: `1px solid #c3c3c3` }}>
            {props.node.frontmatter.title}
            </div>
        </div>              
        </Link>
    </div>

)